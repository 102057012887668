import { useState, useRef, useEffect } from 'react';
import { Table } from 'semantic-ui-react';

import './App.css';
// const data = require("./data.json");

function App() {
  const [data, setData] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const header = useRef(null);
  const headerShadow = useRef(null);

  if (!dataFetched)
    getData();

  useEffect(() => { // Process after rendering is done
    if (header.current===null) return;
    alert('header');
    for (let n=0; n < header.current.children.length; n++) {
      header.current.children[n].style.minWidth = headerShadow.current.children[n].clientWidth + "px";
      header.current.children[n].style.width = headerShadow.current.children[n].clientWidth + (n===0? 0.5 : 1) + "px";
    }
  }, []); // [] runs once since no dependency; [<array of hooks>] means depend on hooks changes

  async function getData() {
    const response = await fetch('https://data-sctjw2uo4a-uc.a.run.app/?event=abc2023');
    const json = await response.json();
  
    console.log("getData()");
    setData(json);
    setDataFetched(true);
  }

  const getHeader = () => {
    if (data.head===undefined)
      return;

    // data.head.unshift('#');
    return (
      data.head.map(s => {
        s = s.replace(/^(@|_)/, "").replace(/_/g, " "); // handle special characters
        s = s.split(' ').map(w => w.slice(0,1).toUpperCase() + w.slice(1,)).join(' '); // Capitalize
        return <Table.HeaderCell>{s}</Table.HeaderCell>
      })
    );
  }

  const getRow = ({row}) => {
    let nodes = [];

    for (var i in row) {
      nodes.push(<Table.Cell>{row[i]}</Table.Cell>);
    }

    return nodes;
  }

  const getBody = () => {
    if (data.body) {
      return (
        data.body.map((row, index) => {
          return <tr key={index}>
            {getRow({row})}
          </tr>
        })
      );
    }
  }

  const TABLE = <Table compact celled striped>
    <Table.Header>
      <Table.Row ref={header} id="header" style={{
        position: "fixed",
        minWidth: "100%",
        width: "100%",
        top: "0px",
      }}>
        {getHeader()}
      </Table.Row>
      <Table.Row ref={headerShadow} id="headerShadow" className='xinvisible'>
        {getHeader()}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {getBody()}
    </Table.Body>
    {/* <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan={}>
          <Button
            floated='right'
            icon
            labelPosition='left'
            primary
            size='small'
          >
            <Icon name='user' /> Add User
          </Button>
          <Button size='small'>Approve</Button>
          <Button disabled size='small'>
            Approve All
          </Button>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer> */}
  </Table>

  return TABLE;
}

export default App;
